export default function(formClass) {
  $(document).ready(function(){
    $(formClass).on('ajax:error',function(event){
      handleErrorOnLogin(event);
    });
  });
}

function handleErrorOnLogin(event) {
  document.getElementById("alert").innerHTML = event.originalEvent.detail[0];
  console.log("ERROR!", event, arguments);
}
