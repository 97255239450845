import { generateAndUploadNewKeyPair, getKeyPair } from "../../chat/crypto";
import getStorageEngine from "../../chat/crypto-session-storage"

 

const PASSWORD_FIELD_CLASS = "passwordfield_for_load_key_pair_on_reset_password"

export default async function() {
  try {
    const passwordField = document.getElementsByClassName(PASSWORD_FIELD_CLASS)[0];
    const password = passwordField.value;
    await generateAndUploadNewKeyPair(password);
    await getKeyPair(password, await getStorageEngine());
  } catch(e) {
    console.log("could not load private key (password wrong, no private key available?)");
  } 
  location.reload();
}
