import ActionCable from "../channels/consumer";

const SELECTOR_OVS_BADGE = '.numberCircle.ovsCircle';
const SELECTOR_CHAT_BADGE = '.numberCircle.chatCircle';


const BADGE_HANDLES = {
  'ovs': function(){
    return $(SELECTOR_OVS_BADGE);
  },
  'chat':  function(){
    return $(SELECTOR_CHAT_BADGE);
  }
}
const BADGE_STATUS = {}

$(document).ready(()=> {
  for(const badgeName of Object.keys(BADGE_HANDLES)) {
    const badgeHandle = BADGE_HANDLES[badgeName]();
    badgeHandle.hide();
  }

  ActionCable.subscriptions.create({
    channel: "BadgeChannel",
    // patient_id: document.querySelector('head').dataset.patientId,
    // user_id: document.querySelector('head').dataset.userId
  }, {
    connected: function() {
      console.debug('Connected to BadgeChannel');
    },
    disconnected: function() {
      console.debug('Disconnected from BadgeChannel');
    },
    received: function(data) {
      console.debug("Received data from BadgeChannel", data)
      // $('#chats').prepend(data['chat']);

      switch(data.action) {
        case 'badge_status':
          // Message that has the current status
          processBadgeStatus(data);
          break;
        case 'users':
          // Handle that the ovs lobby room count changed
          ovsRoomCountChanged(data);
          break;
        case 'chat_count':
          // Handle that the chat count changed
          chatCountChanged(data);
          break;
        default:
          console.debug("No handler for action found", data.action);
          break;
      }
    },
  });
});

const updateCount = function(badgeName, newCount) {
  const badgeHandleFn = BADGE_HANDLES[badgeName];
  if(!badgeHandleFn) {
    console.debug(`Cold not finde badge '${badgeName}', you may have to add it`);
    return
  }
  const jQueryHandle = badgeHandleFn();

  if(newCount > 0) {
    jQueryHandle.show();
    BADGE_STATUS[badgeName] = true
  } else {
    jQueryHandle.hide();
    BADGE_STATUS[badgeName] = false
  }

  jQueryHandle.html(newCount);
}

const ovsRoomCountChanged = function(data) {
  console.debug("Room count changed");

  let ovsBadgeCount = 0;
  for(const user of data.payload.users) {
    ovsBadgeCount += user.room;
  }
  updateCount('ovs', ovsBadgeCount);
}

const chatCountChanged = function(data) {
  console.debug("Chat count changed");
  updateCount('chat', data.payload.count);
}

const processBadgeStatus = function(data) {
  console.debug("Applying current status for badges");
  updateCount('ovs', data.payload.ovs_count )
  updateCount('chat', data.payload.chat_count )
}

// For updating visibility on page change
$(document).on("turbolinks:load", () => {
  for( const badgeName of Object.keys(BADGE_HANDLES)) {
    const status = BADGE_STATUS[badgeName]
    if(status != null) { // status already exists
      const badgeHandle = BADGE_HANDLES[badgeName]()
      if(status) { badgeHandle.show(); }
      else { badgeHandle.hide(); }
    }
  }
});

