import { getKeyPair } from "../../chat/crypto";
import getStorageEngine from "../../chat/crypto-session-storage"

const PASSWORD_FIELD_CLASS = "passwordfield_for_load_key_pair_on_login"

export default async function() {
  const loginButton = document.getElementById('login-button');
  const value = loginButton.value;

  try {
    await sleepInMs(0);
    loginButton.disabled = true;
    loginButton.value = "Schlüssel wird geladen..."
    await sleepInMs(0); // wait for the login button to be disabled

    const passwordField = document.getElementsByClassName(PASSWORD_FIELD_CLASS)[0];
    const password = passwordField.value;
    await getKeyPair(password, await getStorageEngine());
  } catch(e) {
    console.error("could not load private key (password wrong, no private key available?)", e);
  } finally {
    loginButton.value = value;
    loginButton.disabled = false;
  }

  location.reload();
}

const sleepInMs = (timeInMs) => {
  return new Promise((resolve, _) => {
    setTimeout(resolve, timeInMs);
  });
}
